import { Router } from '@/app/providers/router';
import * as Styled from '@/app/styles/app-layouts';
import { useMatchMedia } from '@/shared/lib/hooks/use-match-media.hook';
import { Header } from '@/widgets/header';
import { Sidebar } from '@/widgets/sidebar';

const HtmlContentComponent = () => {
	const isTablet = useMatchMedia('tabletAndMobile');
	// const [openConditionsAndPrivacyPolicyModal] = useConditionsAndPrivacyPolicyModal();

	//TODO: need backend
	// useEffect(() => {
	// 	openConditionsAndPrivacyPolicyModal();
	// }, []);

	return (
		<>
			{!isTablet && <Sidebar />}
			<Styled.ContentWrapper>
				<Header />
				<Router />
			</Styled.ContentWrapper>
		</>
	);
};

export default HtmlContentComponent;
