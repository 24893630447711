export enum AppStoreTag {
	User = 'User',
	Users = 'Users',
	UserDetail = 'UserDetail',
	Boat = 'Boat',
	BoatDetail = 'BoatDetail',
	Dashboard = 'Dashboard',
	Historical = 'Historical',
	RealTimeData = 'RealTimeData',
	RealTimeAlarms = 'RealTimeAlarms',
	RealTimeStatus = 'RealTimeStatus',
	Histogram = 'Histogram',
	Templates = 'Templates',
	Map = 'Map',
	AvailableUsers = 'AvailableUsers',
	Notifications = 'Notifications',
	TermsAndConditions = 'TermsAndConditions',
	PrivacyPolicy = 'PrivacyPolicy',
}
