import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { toastActions } from '@/entities/toast';
import { NoDataError, NoDataErrorTranslation } from '@/shared/constants/no-data-error.constant';
import { StatusCodeError } from '@/shared/constants/status-code-error.constant';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		if (
			action.payload.status === StatusCodeError.Unauthorized ||
			action.payload.originalStatus === StatusCodeError.Unauthorized
		) {
			api.dispatch(
				toastActions.addToast({
					message: t('toast.error.expiredToken'),
					type: 'error',
					title: t('toast.error.title'),
				}),
			);

			return next(action);
		}

		if (action.payload.status === StatusCodeError.NoData) {
			const backendMessage = action.payload.data?.detail as NoDataError;

			const message = Object.values(NoDataError).includes(backendMessage)
				? t(NoDataErrorTranslation[backendMessage])
				: t('toast.error.noDataError');

			api.dispatch(
				toastActions.addToast({
					message,
					type: 'error',
					title: t('toast.error.title'),
				}),
			);
		} else {
			api.dispatch(
				toastActions.addToast({
					message:
						action.payload.data?.detail?.[0]?.msg ??
						action.payload.data?.detail?.message ??
						action.payload.data?.detail ??
						action.payload.data?.message ??
						t('toast.error.serverError'),
					type: 'error',
					title: t('toast.error.title'),
				}),
			);
		}
	}

	return next(action);
};
