export enum NoDataError {
	LocationNotFound = 'Location variables for the boat not found',
	HistogramNotFound = 'Histogram data not found for given period of time',
}

export const NoDataErrorTranslation: Record<
	NoDataError,
	'toast.error.noDataLocationError' | 'toast.error.noDataHistogramError'
> = {
	[NoDataError.LocationNotFound]: 'toast.error.noDataLocationError',
	[NoDataError.HistogramNotFound]: 'toast.error.noDataHistogramError',
};
