import styled from 'styled-components';

import { Autocomplete } from '@/shared/ui/autocomplete';

export const SelectVariables = styled(Autocomplete)`
	width: ${({ theme: { autocomplete } }) => autocomplete.width.l};

	@media ${({ theme: { media } }) => media.mobile} {
		width: ${({ theme: { autocomplete } }) => autocomplete.width.full};
	}
`;
