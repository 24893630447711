export enum AppRoutes {
	Home = '/',
	VirtualCockpit = '/virtual-cockpit',
	HistoricalData = '/historical-data',
	AggregadeData = '/aggregate-data',
	Dashboard = '/dashboard',
	YachtView = '/yacht-view',
	PlantView = '/plant-view',
	MultiYachtView = '/multi-yacht-view',
	MultiPlantView = '/multi-plant-view',
	YachtTrack = '/yacht-track',
	Map = '/map',
	Profile = '/profile',
	Settings = '/profile/timezone-etc',
	Faq = '/profile/faq',
	FaqPrivacy = '/profile/faq/privacy-policy',
	FaqTermsOfService = '/profile/faq/terms-of-service',
	UsersManagement = '/users-management',
	AgreementsSettings = '/agreements-settings',
	EditUser = '/users-management/edit-user/:id',
	YachtsFleet = '/yachts-fleet',
	PlantsManagement = '/plants-management',
	EditYacht = '/yachts-fleet/edit-yacht/:id',
	EditPlant = '/plants-management/edit-plant/:id',
	Support = '/support',
	HelpCenter = '/help-center',
	Privacy = '/privacy-policy',
	TermsOfService = '/terms-of-service',
	Login = '/login',
	ForgotPassword = '/forgot-password',
	CheckEmail = '/check-email',
	NewPassword = '/new-password',
	PasswordReset = '/password-reset',
	AlarmRealTime = 'alarm/real-time',
	AlarmHistory = 'alarm/history',
	AlarmDashboard = 'alarm/dashboard',
	AlarmManagement = 'alarm/management',
	StatusRealTime = 'status/real-time',
	StatusHistory = 'status/history',
	StatusDashboard = 'status/dashboard',
}
