import { MouseEventHandler, useMemo, useState } from 'react';

import { Box } from '@/shared/ui/box';

import * as Styled from './tooltip.styles';
import { TooltipProps } from './tooltip.types';

const TooltipComponent = ({
	children,
	tooltip,
	direction = 'bottom',
	flexDirection = 'column',
	maxWidth,
	position,
	textAlign = 'start',
	display = 'inline-block',
}: TooltipProps) => {
	const [show, setShow] = useState(false);
	const splittedTooltip = useMemo(() => {
		if (tooltip.length > 20) {
			return <Styled.SplittedTooltip>{tooltip}</Styled.SplittedTooltip>;
		}

		return tooltip;
	}, [tooltip]);

	const onShow = () => {
		setShow(true);
	};

	const onHide = () => {
		setShow(false);
	};

	const onRemovePropagation: MouseEventHandler<HTMLDivElement> = (e) => {
		e.stopPropagation();
	};

	return (
		<Box position="relative" display={display} onMouseEnter={onShow} onMouseLeave={onHide}>
			{children}
			{show && (
				<Styled.TooltipPopup
					direction={direction}
					flexDirection={flexDirection}
					maxWidth={maxWidth}
					position={position}
					textAlign={textAlign}
					onClick={onRemovePropagation}
				>
					{splittedTooltip}
				</Styled.TooltipPopup>
			)}
		</Box>
	);
};

export default TooltipComponent;
